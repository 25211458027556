import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Link from "../components/Link";

export default function Blog({ data }) {
  const { posts } = data.blog;

  return (
    <Layout>
      <SEO title={"Blogs"} />
      <h1>My blog posts</h1>

      {posts.map(post => (
        <article key={post.id}>
          <Link to={`./${post.slug}`}>{post.frontmatter.title}</Link>
          <small>
            {post.frontmatter.author}, {post.frontmatter.date}
          </small>
          <p>{post.excerpt}</p>
        </article>
      ))}
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMdx {
      posts: nodes {
        frontmatter {
          title
          author
          date(fromNow: true)
        }
        slug
        excerpt
        id
      }
    }
  }
`;
